.editor_container {
}

.editor_container h1,
.editor_container h2,
.editor_container h3,
.editor_container h4,
.editor_container h5,
.editor_container h6,
.editor_container p,
.editor_container ul,
.editor_container figure {
  padding-bottom: 10px;
}

.editor_container h1 {
  font-size: 2em !important;
  font-weight: bolder !important;
}

.editor_container h2 {
  font-size: 1.5em !important;
  font-weight: bolder !important;
}

.editor_container h3 {
  font-size: 1.17em !important;
  /* font-weight: bolder !important; */
}

.editor_container h4 {
  font-size: 1em !important;
  /* font-weight: bolder !important; */
}

.editor_container h5 {
  font-size: 0.83em !important;
  /* font-weight: bolder !important; */
}

.editor_container h6 {
  font-size: 0.67em !important;
  /* font-weight: bolder !important; */
}

.editor_container .textAlignRight {
  text-align: right !important;
}

.editor_container .textAlignCenter {
  text-align: center !important;
}

.editor_container .textAlignLeft {
  text-align: left !important;
}

.editor_container ul {
  list-style-type: initial;
  padding-left: 40px;
}
