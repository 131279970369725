.opacity-0 {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.sortable {
  position: relative;
}
.add-item:hover {
  transform: scale(1.4);
}
.add-item {
  margin: 20px;
  transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 22px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget-list {
  min-width: 40vw;
}
