.RichEditor-root {
  width: 100%;
  padding: 15px;
  font-size: 14px;
  /* overflow: scroll; */
  background: #fff;
  border: 1px solid #ddd;
  font-family: tahoma, 'Georgia', serif;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  overflow: scroll;
  height: calc(100% - 37px);
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  /*font-family: 'Helvetica', sans-serif;*/
  font-family: IRANYekan, IRANSans, 'Nunito', 'Open Sans', Lato, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.options {
  margin-bottom: 20px;
}

.imageAlignmentTool {
  border-top: 1px solid #e2e2e2;
  margin-top: 5px;
  padding-top: 5px;
}

.imageAlignmentTool > div {
  /*display: flex;
  justify-content: center;
  transform: none !important;*/
}

.imageAlignmentTool > div > div {
  padding-right: 10px;
}

.RichEditor-editor .public-DraftEditor-content h1 * {
  font-size: 1.2em !important;
  font-weight: bolder !important;
}

.RichEditor-editor .public-DraftEditor-content h2 * {
  font-size: 1.15em !important;
  font-weight: bolder !important;
}

.RichEditor-editor .public-DraftEditor-content h3 * {
  font-size: 1.05em !important;
  /* font-weight: bolder !important; */
}

.RichEditor-editor .public-DraftEditor-content h4 * {
  font-size: 0.8em !important;
  /* font-weight: bolder !important; */
}

.RichEditor-editor .public-DraftEditor-content h5 * {
  font-size: 0.6em !important;
  /* font-weight: bolder !important; */
}

.RichEditor-editor .public-DraftEditor-content h6 * {
  font-size: 0.5em !important;
  /* font-weight: bolder !important; */
}

.RichEditor-editor .public-DraftEditor-content figure img {
  margin: auto;
}

.textAlignRight * {
  text-align: right !important;
}

.textAlignCenter * {
  text-align: center !important;
}

.textAlignLeft * {
  text-align: left !important;
}
