@import './font.css';
@import './colors.css';
@import './tooltips.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Poppins, serif !important;
  font-size: 1.7vh !important;
}

body {
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
button:focus {
  outline: 0;
}

select:focus {
  outline: none;
}

select:disabled {
  opacity: 1;
}

input[type='text']:disabled {
  background-color: white;
}

input:focus {
  outline: none;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.scrollbar-none {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
}

.sidebarShape::before {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 4.5rem;
  height: 3.5rem;
  box-shadow: 0.8rem 0.8rem 0 0.1875rem white;
  border: 0.625rem solid transparent;
  border-radius: 3rem 0 3rem 0;
}

.sidebarShape::after {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  width: 4.5rem;
  height: 3.5rem;
  box-shadow: 0.8rem -0.8rem 0 0.1875rem white;
  border: 0.625rem solid transparent;
  border-radius: 0 3rem 0 0;
}

.spinner {
  border: 0.5rem solid #ffffff;
  border-top: 0.5rem solid #ffca29;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes modal {
  from {
    top: -40rem;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
[type='radio']:checked + button {
  border: 2px solid blue !important;
}
[type='radio']:checked ~ .sss {
  border: 2px solid blue !important;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.scrollbar-none {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
}

.preview_container h1,
.preview_container h1 * {
  font-weight: bolder;
  font-size: 2em !important;
}
.preview_container h2,
.preview_container h2 * {
  font-weight: bolder;
  font-size: 1.5em !important;
}
.preview_container h3,
.preview_container h3 * {
  font-weight: bolder;
  font-size: 1.17em !important;
}
.preview_container h4,
.preview_container h4 * {
  font-weight: bolder;
  font-size: 1em !important;
}
.preview_container h5,
.preview_container h5 * {
  font-weight: bolder;
  font-size: 0.83em !important;
}
.preview_container h6,
.preview_container h6 * {
  font-weight: bolder;
  font-size: 0.67em !important;
}
