.container {
  margin: auto;
  overflow: hidden;
  border-radius: 35px;
  width: 414px !important;
  height: 736px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

.container_top {
  position: absolute;
  width: 100%;
  height: 20px;
  z-index: 01000;
  left: 0px;
  right: 0px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.container_top_in {
  /* position: absolute; */
  width: 172px;
  height: 20px;
  background-color: white;
  /* z-index: 01000; */
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  /* left: 133px;
  right: 133px; */
}

/* .container_main {

} */
