.section {
  padding: 10px;
}

.section__title {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 0px;
}

.section__title_edit {
  margin: 0px 15px;
  font-size: 12px;

  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  color: white;
  background-color: lightblue;
}
.section__title_done {
  margin-left: 7px;
  margin-right: 7px;
  font-size: 12px;
  cursor: pointer;

  border-radius: 5px;
  padding: 5px;
  color: white;
  background-color: lightblue;
}
.section__title_done:hover,
.section__title_edit:hover {
  background-color: dodgerblue;
  transition: background-color 200ms ease-in-out;
}
.section-list {
  display: flex;
  min-height: 150px;
  overflow: scroll;
  padding-top: 10px;
}

.section-item-wrapper {
  z-index: 10;
  cursor: grab;
  margin: 0px 15px;
  position: relative;
  border-radius: 8px;
  overflow: visible;
}

.grabbing {
  z-index: 13;
}

.section-item__text {
  filter: drop-shadow(0px 3px 5px #000000bf);

  /* border: 1px solid white; */
  box-sizing: border-box;
  /* overflow: hidden; */
  background: white;
  width: 150px;
  height: 110px;
  display: flex;
  margin: 0;
  padding: 10px;
  border-radius: 8px;

  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow: hidden;
  z-index: 24;
}

.section-item__img {
  filter: drop-shadow(0px 3px 5px #000000bf);

  /* overflow: hidden; */
  width: 150px;
  height: 110px;
  display: flex;
  border-radius: 8px;
  margin: 0;
  z-index: 24;
}

.delete-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 25;
  background: red;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  object-fit: none;
  align-items: center;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  border: 3px solid white;
}
.edit-icon {
  position: absolute;
  top: -10px;
  right: 27px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 25;
  background: #ffca29;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  object-fit: none;
  border: 3px solid white;
}

.ghost-item {
  opacity: 0.5;
  pointer-events: none;
}
