.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltipText {
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 1rem 0.5rem;
  border-radius: 6px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
